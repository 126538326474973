export const SUCCESS = {
  25: [
    "Great work! You made it to the first milestone. We've started to model your voice. You will get an email when it's ready to use.",
    "25 Recordings! We've started to construct your voice, you should get an email in your inbox when it's ready.",
    "Our AI is now understanding how you speak. You will get an email when it's ready to use.",
  ],
  100: ["1-0-0! Are you sure you're not a professional voice actor? You're racing through these sentences!"],
  200: ["2-0-0! Are you sure you're not a professional voice actor? You're racing through these sentences!"],
  300: ["Great work! We've started to model your voice. You will get an email when it's ready to use."],
}

export const TRIAL_USER_SUCCESS = {
  25: [
    "Great work! We've started to model your voice. You will get an email when it's ready to use.",
    "25 Recordings! We've started to construct your voice, you should get an email in your inbox when it's ready.",
    "Our AI is now understanding how you speak. You will get an email when it's ready to use.",
  ],
}

export const UPGRADE_PROMPT = '<br/><br/>The free plan only supports training on 25 samples. To record more samples and train a more accurate voice upgrade your plan.'
export const BUILD_OR_RECORD_MORE_PROMPT = '<br/><br/>Would you like to build your voice now or do you want to record more samples?'

export const MAX_RECORDINGS_FOR_RAPID_VOICE = 3
export const MAX_RECORDINGS_FOR_TRIAL_USERS = 25
export const MAX_RECORDINGS_FOR_PAID_USERS = 300

// if this threshold is reached, then the volume is considered bad by default
export const VOLUME_CLIPPING_THRESHOLD = -3

// under this DBFS, the audio input channel is considered dead/flat
export const DEAD_AUDIO_INPUT_THRESHOLD = -500

// volume is considered good if it is between these two levels
export const MAX_GOOD_VOLUME_THRESHOLD = -12
export const MIN_GOOD_VOLUME_THRESHOLD = -35

// if the silence threshold is held for more than NUM_SILENT_SAMPLES_THRESHOLD,
// the subsequent samples are cut before making calculations (until the silence threshold is surpassed again)
export const VOLUME_SILENCE_THRESHOLD = -35
/* Sample rates are usually either 44100 or 48000. so for 250ms, we have ~12000 samples */
export const NUM_SILENT_SAMPLES_THRESHOLD = 12000

// Live feedback is given on this many previous samples
export const NUM_LIVE_SAMPLES_TO_TEST = 8192

//Return strings from the permissions API.
export const MIC_PERMISSIONS_PROMPT = 'prompt'
export const MIC_PERMISSIONS_GRANTED = 'granted'
export const MIC_PERMISSIONS_DENIED = 'denied'
